body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #24333d;
  color: #AEB8C4;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.section-container {
  width: calc(100% - 110px);
  padding-left: 90px;
  padding-right: 20px;
}

.section-container>div {
  height: 100vh;
}


nav {
  width: 70px;
  height: 100vh;
  position: fixed;
  background-color: #151B25;
}

.nav-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.nav-item {
  list-style-type: none;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: #9CA6B8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link:visited {
  text-decoration: none;
  color: #9CA6B8;
}

.icon {
  width: 75%;
  height: 75%;
  color: #9CA6B8;
}

.scroll-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.canvas {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 0
}

#profile-pic {
  border-radius: 50%;
  width: 10%;
  height: auto;
  border: 3px solid #AEB8C4;
}

p {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  p {
    font-size: 17px;
  }
}

@media screen and (max-width: 550px) {
  p {
    font-size: 15px;
  }
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-form {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 60px 100px;
}

.contact-form>* {
  margin: 10px;
}

.contact-form>textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.contact-form>input {
  padding: 10px 18px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
}

#btn {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.contact-form>label {
  font-size: 20px;
}

#about>p {
  width: 50%;
  text-align: center;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.image {
  width: 752px;
  height: 423px;
  border-radius: 20px;
}



.right {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 3rem;
  color: #ccc;
  z-index: 10;
  cursor: pointer;
}

.left {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 3rem;
  color: #ccc;
  z-index: 10;
  cursor: pointer;
}

.skills-container {
  display: flex;
  padding-top: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 20vh;
  width: 65%;
  flex-flow: wrap;
}
.skills-container>p {
  font-size: 16px;
}


.skill-icon {
  width: 40px;
  height: 40px;
  padding: 20px;
}

#portfolio{
  justify-content: center;
}

#portfolio>*{
  margin: -10px;
}

#contact>form>*{
  z-index: 1;
}

@media screen and (max-width: 875px) {
  .image{
    width: 512px;
    height: 288px;
    border-radius: 10px;
  }
  .slider{
    height: 40vh;
  }
  #about{
    height: 150vh;
  }
  #contact{
    height: 150vh;
  }
  #portfolio{
    height: 150vh;
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 800px) {
  .image{
    width: 450px;
    height: 260px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 600px) {
  .image{
    width: 304px;
    height: 171px;
    border-radius: 10px;
  }
  .slider{
    height: 35vh;
  }
  .canvas{
    display: none;
  }
  nav {
    width: 100%;
    height: 6vh;
    position: fixed;
    background-color: #151B25;
  }
  
  .nav-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }
  
  .icon {
    width: 30%;
    height: 30%;
    color: #9CA6B8;
  }
}

@media screen and (max-width: 400px) {
  .image{
    width: 224px;
    height: 126px;
    border-radius: 10px;
  }
  .slider{
    height: 30vh;
  }
  .canvas{
    display: none;
  }
  #about{
    height: 150vh;
  }
}